import React from 'react'

const PatientSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
    >
      <path
        d="M1 15C1 13.9391 1.42143 12.9217 2.17157 12.1716C2.92172 11.4214 3.93913 11 5 11H13C14.0609 11 15.0783 11.4214 15.8284 12.1716C16.5786 12.9217 17 13.9391 17 15"
        stroke="#E5EBF6"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M9 7C10.6569 7 12 5.65685 12 4C12 2.34315 10.6569 1 9 1C7.34315 1 6 2.34315 6 4C6 5.65685 7.34315 7 9 7Z"
        stroke="#E5EBF6"
        strokeWidth="2"
      />
    </svg>
  );
}

export default PatientSvg