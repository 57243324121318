import React from 'react'
import { WelcomeBox,DashboardCards } from '../../components/DashboardComponents'

const Dashboard = () => {
  return (
    <div className="w-full h-fit  px-2 py-10 sm:p-10">
      <WelcomeBox />
      <div className="flex w-full h-fit md:px-3 xl:px-10 pt-10 ">
        <DashboardCards />
      </div>
    </div>
  );
}

export default Dashboard