import { axiosAuthorized } from "../../api/config";

export const getOnlineAppointments = async (mode='New') => {
    try {
        let url = `labcart/new_labcart`
        if (mode === "Completed") {
            url = `/labcart/test_completed`
        }
        const response = await axiosAuthorized.get(url);
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateOnlineAppointmentStatus = async (status, pdf_upload = null, appointmetnId) => {
    try {
        const requestData = pdf_upload ? { status, pdf_upload } : { status };
        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };
        const response = await axiosAuthorized.put(`/labcart/update_labcart/${appointmetnId}`, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}
