import React, { useEffect, useMemo } from "react";
import * as echarts from "echarts";
import "./ChartSection.css"
import SimpleDropdown from "../Patients/SimpleDropDown";

const ChartSection = () => {
  const option = useMemo(() => {
    return {
      title: {
        left: "center",
        top: "center",
      },
      series: [
        {
          type: "pie",
          data: [
            {
              value: 335,
              name: "Male",
              itemStyle: { color: "#FFA901" },
            },
            {
              value: 234,
              name: "Female",
              itemStyle: { color: "#7A6EFE" },
            },
            {
              value: 1548,
              name: "Child",
              itemStyle: { color: "#24A7FB" },
            },
          ],
          radius: ["50%", "70%"],
        },
      ],
    };
  }, []);
  useEffect(() => {
    const chart = echarts.init(document.getElementById("doughnut-chart"));
    chart.setOption(option);
    window.addEventListener("resize", () => {
      chart.resize();
    });

    return () => {
      chart.dispose();
    };
  }, [option]);

  return (
    <div className="chart-section-container">
      <div className="chart-section-header">
        <div className="flex font-semibold">Gender</div>
        <div className="flex">
          <SimpleDropdown />
        </div>
      </div>
      <div
        className="chart-container"
        id="doughnut-chart"
        style={{ width: "100%", height: "252px" }}
      />
    </div>
  );
};

export default ChartSection;
