import React from 'react'

const NoUsersFound = () => {
  return (
    <div className="flex w-full h-full justify-center items-center font-semibold">
      No users found !
    </div>
  );
}

export default NoUsersFound