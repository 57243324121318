import React from 'react'
import "./Icons.css"

const SideIcons = () => {
  return (
    <div className="sideicons-container">
      <div className="sideicon">
        <img
          src="/assets/svgs/red_lock.svg"
          className="sideicon-image"
          alt=""
        />
      </div>
      <div className="sideicon">
        <img
          src="/assets/svgs/red_message.svg"
          className="sideicon-image"
          alt=""
        />
      </div>
      <div className="sideicon">
        <img src="/assets/svgs/red_lab.svg" className="sideicon-image" alt="" />
      </div>
      <div className="sideicon">
        <img
          src="/assets/svgs/red_door.svg"
          className="sideicon-image"
          alt=""
        />
      </div>
    </div>
  );
}

export default SideIcons