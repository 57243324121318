import "./Icons.css"

import React from 'react'

const BottomIcons = () => {
  return (
    <div className="bottomicons-container">
  <div className="bottomicon">
    <img
      src="/assets/svgs/red_lock.svg"
      className="bottomicon-image"
      alt=""
    />
  </div>
  <div className="bottomicon">
    <img
      src="/assets/svgs/red_message.svg"
      className="bottomicon-image"
      alt=""
    />
  </div>
  <div className="bottomicon">
    <img
      src="/assets/svgs/red_lab.svg"
      className="bottomicon-image"
      alt=""
    />
  </div>
  <div className="bottomicon">
    <img
      src="/assets/svgs/red_door.svg"
      className="bottomicon-image"
      alt=""
    />
  </div>
</div>  
  )
}

export default BottomIcons

