import React, { useMemo } from 'react'
import { Table } from '../../Common';
import { TbUserEdit } from "react-icons/tb";
import {MdOutlineDeleteOutline} from 'react-icons/md'
import { deleteAppointment } from '../../../utils/appointmentsUtils/appointmentsUtils';

const AppointmentsTable = ({ datas,setEditData,setOpen,setOpenTracker, setAppointmentsFn }) => {
  const headings = useMemo(
    () => [ "Name", "P-Id", "Gender", "Age", "Test", "Status","Tracker","Edit","Delete"],
    []
  );
  const delAppointment = (appointmentId) => {
    deleteAppointment(appointmentId)
    .then(res=>setAppointmentsFn())
    .catch(err=>console.log(err));
  }
  return (
    <Table headings={headings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{data.name}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data.patient_id}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data.gender}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">{data.age}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data.test_name}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data.status}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize cursor-pointer">
              <button
                className='text-white text-sm bg-primary rounded-md py-1 px-2'
                onClick={() => {
                  setOpen(false);
                  setEditData(data);
                  setOpenTracker(true);
                }}
              >Update</button>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                setOpenTracker(false);
                setEditData(data);
                setOpen(true);
              }}
            >
              <TbUserEdit />
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                delAppointment(data?._id);
              }}
            >
              <MdOutlineDeleteOutline color="red" />
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
}

export default AppointmentsTable