import axios from "axios"

const baseURL = process.env.REACT_APP_SERVER_URL; 
export const axiosAuthorized = axios.create({ baseURL: baseURL,});

export const axiosInstance = axios.create({baseURL: baseURL,});

axiosAuthorized.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosAuthorized.interceptors.response.use(
    (response) => response,
    (error) => {
        const originalRequest = error.config;
        if (
            error.response &&
            error.response.status === 403 &&
            error.response.data.message === "Access forbidden, Invalid token"
        ) {
            return axiosInstance
                .post("/refresh/token", null, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
                    },
                })
                .then((response) => {
                    const responseData = response.data;
                    const accessToken = responseData.accessToken;
                    localStorage.setItem("accessToken", accessToken);
                    if (originalRequest && originalRequest.headers) {
                        originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
                    }
                    return axiosAuthorized(originalRequest);
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        }
        return Promise.reject(error);
    }
);

