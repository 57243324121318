import React from 'react'
import "./DashboardCard.css"
import { AppointmentCard,TotalPatientCard,MessageConsulting, VideoConsulting } from './DashbhoardCardSvg';

const DashboardCards = () => {
  return (
    <div className="dashboardcard-container">
      <AppointmentCard />
      <TotalPatientCard />
      <MessageConsulting />
    </div>
  );
}

export default DashboardCards