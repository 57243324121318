import React from "react";
import PackagesMainSection from "../../components/PackagesComponents/PackagesMainSection";

const Packages = () => {
  return (
    <div className="w-full h-fit  px-2 pt-10 sm:p-10 relative">
        <PackagesMainSection/>
    </div>
  );
};

export default Packages;
