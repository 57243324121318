export const validateAppointmentForm = (initialState, setError) => {

    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    const genderPattern = /^(?!Select Gender$).+/;
    const priorityPattern = /^(?!Select Priority$).+/;
    const agePattern = /^\d+$/;
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
    const emailPattern = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
    const phoneNumberPattern = /^(?!0{10}$)\d{10}$/;

    if (!(initialState.name).trim().length) {
        setError({ global: "Invalid Name" });
        return false;
    }

    if (!initialState.patient_id.length) {
        setError({ global: 'Invalid Patient ID format' });
        return false;
    }

    if (!genderPattern.test(initialState.gender)) {
        setError({ global: "Please Select Gender" });
        return false;
    }

    if (!agePattern.test(initialState.age)) {
        setError({ global: "Age must be a number." });
        return false;
    }

    if (initialState.email.length && !emailPattern.test(initialState.email)) {
        setError({ global: "Invalid Email" });
        return false;
    }

    if (!phoneNumberPattern.test(initialState.phone)) {
        setError({ global: "Invalid Phone Number" });
        return false;
    }

    if (!(initialState.test_name).trim().length) {
        setError({ global: "Invalid Test Name" });
        return false;
    }

    if (!(initialState.doctor_name).trim().length) {
        setError({ global: "Invalid Doctor Name" });
        return false;
    }

    if (!(initialState.supportInfo).trim().length) {
        setError({ global: "Invalid Support Info" });
        return false;
    }

    if (!(initialState.testReason).trim().length) {
        setError({ global: "Invalid Test Reason" });
        return false;
    }

    if (!priorityPattern.test(initialState.priority)) {
        setError({ global: "Please Select Priority" });
        return false;
    }

    if (!timePattern.test(initialState.time)) {
        setError({ global: "Invalid Time" });
        return false;
    }

    if (!datePattern.test(initialState.date)) {
        setError({ global: "Invalid Date" });
        return false;
    }

    if (!(initialState.description).trim().length) {
        setError({ global: "Invalid Description" });
        return false;
    }

    return true;
};


