import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai';

const ShowImage = ({image,setImage}) => {
  return (
    <div className="w-full relative  max-h-[355px]">
      {!image ? (
        <img
          src={
            "https://www.aputf.org/wp-content/uploads/2015/06/default-placeholder1-1024x1024-570x321.png"
          }
          className="rounded-md border w-[282px] h-[159px] object-cover"
          alt="default_image"
        />
      ) : (
        <img
          src={image instanceof File ? URL.createObjectURL(image) : image}
          className="rounded-md border  w-[282px] h-[159px] object-cover"
          alt="user_image"
        />
      )}
      {image && (
        <button className="flex absolute top-2 right-2">
          <AiOutlineCloseCircle
            fontSize={16}
            color="red"
            onClick={() => {
              setImage(null);
            }}
          />
        </button>
      )}
    </div>
  );
}

export default ShowImage