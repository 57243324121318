import React from 'react'

const EarningsSvg = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="204"
        height="94"
        viewBox="0 0 204 94"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M199.128 50.2008C183.53 60.5985 151.803 47.6864 133.655 56.1144C125.117 60.0794 137.637 70.5272 128.684 74.0849C119.322 77.8051 101.14 65.6318 91.1999 68.6665C75.832 73.358 84.7881 87.5169 69.401 92.1828C56.641 96.0521 39.1649 92.4173 27.7169 86.4934C16.3079 80.5896 14.2323 69.9393 9.19482 61.0281C4.36671 52.4872 -3.15697 31.8223 2.57477 23.8142C9.19912 14.559 19.7815 27.2437 27.5074 18.3905C34.84 9.98801 29.2349 8.75374 39.5025 1.85763C48.8173 -4.39848 60.5158 7.08194 73.0124 7.11815C88.0121 7.1616 86.8669 13.1723 98.8304 18.5965C105.964 21.831 114.695 15.3946 123.121 14.9112C144.795 13.6677 168.891 5.62542 187.671 13.4993C201.194 19.1696 209.978 42.9677 199.128 50.2008Z"
          fill="#325087"
        />
      </svg>
    </div>
  );
}

export default EarningsSvg