import React from 'react'
import { OnlinePackagesAppointmentsMainSection } from '../../components/OnlinePackageAppointmentsComponents';

const OnlinePackagesAppointments = () => {
  return (
    <div className="w-full h-fit px-2 pt-10 sm:p-10 relative">
      <OnlinePackagesAppointmentsMainSection />
    </div>
  );
}

export default OnlinePackagesAppointments

