import React from 'react'
import { verifyEmail } from '../../../utils/loginUtils/loginUtils';
import { TextInput } from '../../Common/FormComponents';
import { TiArrowLeft } from "react-icons/ti";

const VerifyEmail = ({loginData,setErr,setLoading,handleLoginData,clearForm,setForgot,err,loading,setEmailVerfied}) => {
    const handleSubmit = (e)=>{
          e.preventDefault();
            if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(loginData.email)) {
              setErr({ global: "Invalid Email" });
              return;
            }
          setLoading(true);
        verifyEmail(loginData.email, setErr).then(res => { setEmailVerfied(true); }).catch(err => console.log(err)).finally(()=>{setLoading(false)});
        }
  return (
    <form onSubmit={(e) => handleSubmit(e)} className="flex w-full h-full justify-center items-center">
      <div className="flex w-4/5 lg:w-1/2 h-fit flex-col gap-5 px-10 lg:px-0">
        <TextInput
          data={loginData}
          handleInput={handleLoginData}
          name="Email"
          formName={"email"}
          setError={setErr}
        />
        <span
          onClick={() => {
            setErr({ global: "" });
            clearForm();
            setForgot(false);
          }}
          className="w-full flex gap-1 items-center justify-end text-secondary text-sm cursor-pointer hover:text-primary"
        >
          <TiArrowLeft /> back
        </span>
        {err.global && (
          <div className="flex w-full h-fit text-red-600 justify-center items-center font-semibold text-sm">
            {err.global}
          </div>
        )}
        <button
          disabled={loading}
          onClick={(e) => {
            handleSubmit(e);
          }}
          className="w-full h-fit py-1 justify-center items-center px-2 text-white bg-primary rounded-md"
        >
          {loading ? "Loading..." : "Submit"}
        </button>
      </div>
    </form>
  );
}

export default VerifyEmail