export const validateLoginForm = (initialState, setErr) => {
    const idPattern = /^LPH-Lab-\d*$/;
    const passwordPattern = /^.{8,}$/

    if (!idPattern.test(initialState.labId.trim())) {
        setErr({ global: "Invalid Lab Id" });
        return;
    }
    if (!passwordPattern.test(initialState.password)) {
        setErr({ global: "Invalid Password" });
        return;
    }
    return true;
}