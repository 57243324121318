import React from 'react'

const HospitalSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
    >
      <path
        d="M3.5 23V15.039C3.50007 12.3734 2.9673 9.73473 1.933 7.278L1.5 6.25V6H7.5M20.5 23V15.039C20.4999 12.3734 21.0327 9.73473 22.067 7.278L22.5 6.25V6H16.5M16.5 23V16.5H7.5V23M24 24.5H0M12 3.5V6M12 6V8.5M12 6H14.5M12 6H9.5M12 16.5V23M7.5 1.5H16.5V10.5H7.5V1.5Z"
        stroke="#E5EBF6"
        strokeWidth="2"
      />
    </svg>
  );
}

export default HospitalSvg