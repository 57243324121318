import React, { useState } from 'react';
import { ImageInput, ShowImage, TextAreaInput, TextInput } from '../Common/FormComponents';
import { GrFormClose } from 'react-icons/gr';
import { useForm } from '../../hooks';
import { addCategory } from '../../utils/categoriesUtils/categoriesUtils';

const CategoryModal = ({ setOpen, editData = null, setEditData, setCategoriesFn, user }) => {
  const initailsState = {
    category_name: editData?.category_name || "",
    category_description: editData?.category_description || "",
    meta_name: editData?.meta_name || "",
    meta_description: editData?.meta_description || "",
  };
  const [category_icon, setCategoryImage] = useState(editData?.category_icon || null);
  const [formData, handleFormData,clearForm] = useForm(initailsState);
  const [loading,setLoading] = useState(false);
  const [err, setErr] = useState({ global: "" });
  const handleSubmit = (e) => {
      e.preventDefault();
    if(!formData.category_name.trim().length){
        setErr({ global: "Invalid Category" });
        return;
    }
    if(!formData.category_description.trim().length){
        setErr({ global: "Invalid Description" });
        return;
    }
    if(!category_icon){
        setErr({ global: "Please Selecte Image" });
        return;
    }
      setLoading(true);
      addCategory(formData, category_icon, editData?._id)
        .then((res) => {
          setCategoriesFn();
          setEditData(null);
          setCategoryImage(null);
          clearForm();
          setOpen(false);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
  }
  return (
    <div className="absolute flex w-full h-screen top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div className="flex bg-white w-full max-w-screen-md overflow-y-auto h-full border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col">
        <div className="flex w-full h-fit items-center justify-between font-semibold">
            <span>{editData ? "Edit Category" : "Add New Category"}</span>
          <span className="cursor-pointer" onClick={() => { setOpen(false); setEditData(null); }}><GrFormClose fontSize={16}/></span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
            <TextInput name='Category' formName={"category_name"} data={formData} handleInput={handleFormData} setError={setErr}/>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
            <TextAreaInput name='Description' formName={"category_description"} data={formData} handleInput={handleFormData} setError={setErr}/>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
            <TextInput isHide={true} name='Meta Name' formName={"meta_name"} data={formData} handleInput={handleFormData} setError={setErr}/>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
            <TextAreaInput isHide={true} name='Meta Description' formName={"meta_description"} data={formData} handleInput={handleFormData} setError={setErr}/>
        </div>
    
        <div className="flex w-full h-fit justify-between gap-5">
            <ShowImage image={category_icon} setImage={setCategoryImage}/>
            <ImageInput imageName={"Category Image"} setImage={setCategoryImage}/>
        </div>
        {err.global && (<div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">{err.global}</div>)}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center" onClick={() => { setOpen(false); setEditData(null); }}>
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center " disabled={loading} onClick={(e) => {handleSubmit(e);}}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryModal;