import React, { useState } from 'react';
import {GrFormClose} from "react-icons/gr"
import { useForm } from '../../../hooks';
import { DateInput, NumberInput, SelectInput, TextAreaInput, TextInput, TimeInput } from '../../Common/FormComponents';
import { validateAppointmentForm } from '../../../utils/appointmentsUtils/validateAppointmentsForm';
import { addAppointment } from '../../../utils/appointmentsUtils/appointmentsUtils';

const AppointmentModal = ({ setOpen,user, editData = null, setEditData = () => { }, setAppointmentsFn }) => {
  const appointmentInitialState = {
    patient_id: editData?.patient_id || "",
    name: editData?.name || "",
    gender: editData?.gender || "",
    age: editData?.age || 1,
    doctor_name: editData?.doctor_name || "",
    test_name: editData?.test_name || "",
    test: editData?.test || "",
    supportInfo: editData?.support_info || "",
    priority: editData?.priority || "Select Priority",
    testReason: editData?.test_reason || "",
    description: editData?.description || "",
    date: editData?.date || "",
    time: editData?.time || "",
    email: editData?.email || "",
    phone: editData?.phone || "",
  };
  const [appointmentFormData, handleAppointmentForm] = useForm(appointmentInitialState);
  const [loading,setLoading] = useState(false);
  const [err, setErr] = useState({ global: "" });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateAppointmentForm(appointmentFormData, setErr)) {
      setLoading(true);
      addAppointment(appointmentFormData,user?._id, editData?._id)
      .then(res => { setAppointmentsFn(); setEditData(null); setOpen(false) })
      .catch(err => console.log(err))
      .finally(()=>setLoading(false));
    }
  }
  return (
  <div className="absolute flex w-full h-screen top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div className="flex bg-white w-full max-w-screen-md overflow-y-auto h-full border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col">
        <div className="flex w-full h-fit items-center justify-between font-semibold">
            <span>{"Add New Appointment"}</span>
          <span className="cursor-pointer" onClick={() => { setOpen(false); setEditData(null); }}><GrFormClose fontSize={16}/></span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
            <TextInput formName={"name"} name={"Name"} data={appointmentFormData} handleInput={handleAppointmentForm} setError={setErr} />
            <TextInput formName={"patient_id"} name={"Patient Id"} data={appointmentFormData} handleInput={handleAppointmentForm} setError={setErr}/>
            <SelectInput formName={"gender"} name={"Gender"} data={appointmentFormData} items={["Select Gender","Male","Female"]} handleInput={handleAppointmentForm} setError={setErr} />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
            <NumberInput formName={"age"} name={"Age"} data={appointmentFormData} handleInput={handleAppointmentForm} setError={setErr} />
            <TextInput formName={"email"} name={"Email"} isHide={true}  data={appointmentFormData} handleInput={handleAppointmentForm} setError={setErr} />
            <TextInput formName={"phone"} name={"Phone"} data={appointmentFormData} handleInput={handleAppointmentForm} setError={setErr} />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
            <TextInput formName={"test_name"} name={"Test Name"} data={appointmentFormData} handleInput={handleAppointmentForm} setError={setErr} />
            <TextInput formName={"doctor_name"} name={"Doctor Name"} data={appointmentFormData} handleInput={handleAppointmentForm} setError={setErr} />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
            <TextInput formName={"supportInfo"} name={"Support Info"} data={appointmentFormData} handleInput={handleAppointmentForm} setError={setErr} />
            <TextInput formName={"testReason"} name={"Test Reason"} data={appointmentFormData} handleInput={handleAppointmentForm} setError={setErr} />
            <SelectInput name={"Priority"} formName={"priority"} data={appointmentFormData} handleInput={handleAppointmentForm} items={["Select Priority","General","Emergency"]} setError={setErr}/>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TimeInput name={"Time"} formName={"time"} data={appointmentFormData} handleInput={handleAppointmentForm} setError={setErr}/>
          <DateInput name={"Date"} formName={"date"} data={appointmentFormData} handleInput={handleAppointmentForm} setError={setErr} />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
            <TextAreaInput formName={"description"} name={"Description"} data={appointmentFormData} handleInput={handleAppointmentForm} setError={setErr}/>
        </div>
        {err.global && (<div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">{err.global}</div>)}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center" onClick={() => { setOpen(false); setEditData(null); }}>
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center " disabled={loading} onClick={(e) => {handleSubmit(e);}}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppointmentModal