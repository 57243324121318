import React from 'react'
import { TestMainSection } from '../../components/TestsComponents'

const Tests = () => {
  return (
    <div className="w-full h-fit  px-2 pt-10 sm:p-10 relative">
      <TestMainSection />
    </div>
  );
}

export default Tests