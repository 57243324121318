import React, { useCallback, useEffect, useState } from 'react'
import { NoDataFound, SearchInput } from '../../Common';
import AppointmentMode from '../AppointmentMode/AppointmentMode';
import OnlinePackagesAppointmentsTable from '../OnlinePackageAppointmentsTable/OnlinePackagesAppointmentsTable';
import OnlinePackageAppointmentTrackerModal from '../OnlinePackageAppointmentTrackerModal/OnlinePackageAppointmentTrackerModal';
import { getPackagesOnlineAppointments } from '../../../utils/packagesUtils/packagesUtils';

const OnlinePackagesAppointmentsMainSection = () => {
  const [appointments, setAppointments] = useState([]);
  const [selected, setSelected] = useState("New");
  const [openTracker, setOpenTracker] = useState(false);
  const [editData, setEditData] = useState(null);
  const setOnlineApponintmentsFn = useCallback(() => { getPackagesOnlineAppointments(selected).then(res => { setAppointments(res.data.data) }).catch(err => console.log(err)) }, [selected]);
  useEffect(() => { setOnlineApponintmentsFn() }, [setOnlineApponintmentsFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = appointments?.filter((patient) => {
      const nameMatch = patient?.name?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
        const phoneMatch = patient?.phone_number?.toString()?.toLowerCase()?.includes(searchQuery?.toLocaleLowerCase());
        const emailMatch = patient?.email_id?.toLowerCase()?.includes(searchQuery?.toLocaleLowerCase());
        const shipAdd = patient?.shipping_address?.toLowerCase()?.includes(searchQuery?.toLocaleLowerCase());
        const billAdd = patient?.billing_address?.toLowerCase()?.includes(searchQuery?.toLocaleLowerCase());
        return nameMatch || phoneMatch || emailMatch || shipAdd || billAdd;
    });
    setFilteredData(filteredData);
  }, [appointments, searchQuery]);
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex font-semibold text-lg">Packages Orders</div>
        <div className="flex">
          <div className="flex">
            <SearchInput
              placeholder={"Search by Name, Phone, Email, Address"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      {openTracker && (
        <OnlinePackageAppointmentTrackerModal
          setEditData={setEditData}
          editData={editData}
          setOpenTracker={setOpenTracker}
          setAppointmentsFn={setOnlineApponintmentsFn}
        />
      )}
      <div className="flex  w-full h-full mt-5 justify-between items-center ">
        <AppointmentMode selected={selected} setSelected={setSelected} />
      </div>
      {filteredData?.length > 0 ? (
        <OnlinePackagesAppointmentsTable
          datas={filteredData}
          setOnlineAppointmentsFn={setOnlineApponintmentsFn}
          setOpenTracker={setOpenTracker}
          setEditData={setEditData}
        />
      ) : (
        <div className="flex mt-10">
          <NoDataFound data={"appointments"} />
        </div>
      )}
    </>
  );
}

export default OnlinePackagesAppointmentsMainSection