import React, { useCallback, useEffect, useState } from 'react'
import { NoDataFound, SearchInput } from '../Common';
import { BsPlus } from "react-icons/bs";
import { useSelector } from 'react-redux';
import { getCategories } from '../../utils/categoriesUtils/categoriesUtils';
import CategoriesTable from './CategoriesTable';
import CategoryModal from './CategoryModal';

const CategoriesMainSection = () => {
    const user = useSelector((state) => state.userReducer.lab);
    const [open, setOpen] = useState(false);
    const [editData,setEditData] = useState(null);
    const [categories, setCategories] = useState([]);
    const setCategoriesFn = useCallback(() =>{
      getCategories().then(res => { setCategories(res.data.data) }).catch(err => console.log(err));
    }, []);
  useEffect(() => { setCategoriesFn() }, [setCategoriesFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = categories?.filter((category) => {
      const nameMatch = category?.category_name?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      return nameMatch ;
    });
    setFilteredData(filteredData);
  }, [categories, searchQuery]);
  return (
    <>
      {open && (
        <CategoryModal
          user={user}
          setEditData={setEditData}
          setOpen={setOpen}
          setCategoriesFn={setCategoriesFn}
          editData={editData}
        />
      )}
      <div className="flex justify-between items-center">
        <div className="flex font-semibold text-lg">Categories</div>
        <div className="flex">
          <div className="flex">
            <SearchInput
              placeholder={"Search by Name"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="flex  w-full h-full mt-5 justify-between items-center ">
        <div className="flex font-semibold gap-3 text-primary">Categories</div>
        <div className="flex gap-2">
          <button
            className="bg-secondary text-white   text-sm rounded-md flex gap-2 p-2 active:bg-secondary items-center"
            onClick={() => {
              setOpen(true);
            }}
          >
            <span>
              <BsPlus fontSize={18} />
            </span>
            <span className="flex gap-1">Category</span>
          </button>
        </div>
      </div>
      {filteredData?.length > 0 ? (
        <CategoriesTable
          datas={filteredData}
          setEditData={setEditData}
          setOpen={setOpen}
          setCategoriesFn={setCategoriesFn}
        />
      ) : (
        <div className="flex w-full justify-center items-center mt-10">
          <NoDataFound data={"categories"} />
        </div>
      )}
    </>
  );
}

export default CategoriesMainSection