import React from 'react'
import "./SelectInput.css"
import SelectOption from '../../SelectOption/SelectOption';
const SelectInput = ({ name="",formName, data, handleInput, setError, items, isHide=false }) => {
  return (
    <div className="lable-and-filed">
      {name.length > 0 && <label htmlFor={name} className="label">
        {name} <span hidden={isHide} className="text-red-600">*</span>
      </label>}
      <div className="input-coantiner" onClick={() => setError({global:""})}>
        <SelectOption
          items={items}
          onItemChange={handleInput}
          selectedItem={data[formName]}
          name={formName}
        />
      </div>
    </div>
  );
};

export default SelectInput