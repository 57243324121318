export const validateTestModalForm = (testFormData,image,category, setErr) => {
    const amountPattern = /^\d+(\.\d{1,2})?$/;
    const percentagePattern = /^\d{1,2}(\.\d{1,2})?$/;
    const servicePattern = /^(lab\/home|lab|home)$/;

    if (!testFormData.test_name.trim().length) {
        setErr({ global: "Invalid Test Name" });
        return false;
    }

    if (category === 0) {
        setErr({ global: "Invalid Category" });
        return false;
    }
    
    if (!servicePattern.test(testFormData.service)) {
        setErr({ global: "Invalid Service" });
        return false;
    }

    if (!amountPattern.test(testFormData.price)) {
        setErr({ global: "Invalid Price" });
        return false;
    }

    if (!percentagePattern.test(testFormData.discount)) {
        setErr({ global: "Invalid Discount" });
        return false;
    }

    if (testFormData.gender === "Select Gender") {
        setErr({ global: "Invalid Gender" });
        return false;
    }
    if (!testFormData.sample.trim().length) {
        setErr({ global: "Invalid Sample" });
        return false;
    }
    if (!testFormData.age_group.trim().length) {
        setErr({ global: "Invalid Age Group" });
    }

    if (!testFormData.test.trim().length) {
        setErr({ global: "Invalid Test Description" });
        return false;
    }
    
    if (!testFormData.method.trim().length) {
        setErr({ global: "Invalid Test Method" });
        return false;
    }

    if (!testFormData.test_required.trim().length) {
        setErr({ global: "Invalid Test Required For" });
        return false;
    }
    
    if (!testFormData.clinical_utility.trim().length) {
        setErr({ global: "Invalid Clinical Utility" });
        return false;
    }
    
    if (!testFormData.patient_prepration.trim().length) {
        setErr({ global: "Invalid Patient Preperation" });
        return false;
    }
    
    if (!testFormData.health_condition.trim().length) {
        setErr({ global: "Invalid Health Condition" });
        return false;
    }

    if (!image) {
        setErr({ global: "Select Image" });
        return false;
    }

    return true;
};
