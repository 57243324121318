import React, { useCallback, useEffect, useState } from 'react'
import { NoDataFound, SearchInput } from '../Common';
import { BiTestTube } from 'react-icons/bi';
import TestsTable from './TestsTable';
import { getTests } from "../../utils/testsUtils/testsUtils";
import TestModal from './TestModal';
import { useSelector } from 'react-redux';

const TestMainSection = () => {
    const user = useSelector((state) => state.userReducer.lab);
    const [open, setOpen] = useState(false);
    const [editData,setEditData] = useState(null);
    const [tests, setTests] = useState([]);
    const setTestsFn = useCallback(() =>{
      getTests(user?._id).then(res => { setTests(res.data.data) }).catch(err => console.log(err));
    }, [user?._id]);
  useEffect(() => { setTestsFn() }, [setTestsFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = tests?.filter((test) => {
      const nameMatch = test?.test_name.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const tIdMatch = test?.test_id?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const departmentMatch = test?.department?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const categoryMatch = test?.category?.category_name
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      return nameMatch || tIdMatch || departmentMatch || categoryMatch ;
    });
    setFilteredData(filteredData);
  }, [tests, searchQuery]);
  return (
    <>
      { open && <TestModal user={user} setEditData={setEditData} setOpen={setOpen} setTestsFn={setTestsFn} editData={editData}/> }
      <div className="flex justify-between items-center">
        <div className="flex font-semibold text-lg">Tests</div>
        <div className="flex">
          <div className="flex">
            <SearchInput placeholder={"Search by Name, Id, Department, Category"} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          </div>
        </div>
      </div>
      <div className="flex  w-full h-full mt-5 justify-between items-center ">
        <div className="flex font-semibold gap-3 text-primary">Tests</div>
        <div className="flex gap-2">
          <button
            className="bg-secondary text-white   text-sm rounded-md flex gap-2 p-2 active:bg-secondary items-center"
            onClick={() => {
              setOpen(true);
            }}
          >
            <span>
              <BiTestTube />
            </span>
            <span className="flex gap-1">Test</span>
          </button>
        </div>
      </div>
      {filteredData?.length > 0 ? (
        <TestsTable datas={filteredData} setEditData={setEditData} setOpen={setOpen} setTestFn={setTestsFn} />
      ) : (
        <div className="flex w-full justify-center items-center mt-10">
          <NoDataFound data={"tests"} />
        </div>
      )}
    </>
  );
}

export default TestMainSection