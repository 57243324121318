import React from "react";
import "./SearchInput.css";
const SearchInput = ({  searchQuery,setSearchQuery,  placeholder,}) => {
  const hanldeQueryChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };
  return (
    <>
      <input
        type="text"
        placeholder={placeholder}
        className="search-input"
        value={searchQuery}
        onChange={(e) => {
          hanldeQueryChange(e);
        }}
      />
    </>
  );
};

export default SearchInput;