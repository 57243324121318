import React, { useMemo } from 'react'
import { Table } from '../../Common';

const OnlineAppointmentsTable = ({ datas, setOpenTracker, setEditData }) => {
      const headings = useMemo(
        () => ["Name","Phone","Email","Shipping Address","Billing Address","Price","Tracker"],
        []
      );
  return (
    <>
      <Table headings={headings}>
        {datas?.map((data, index) => (
          <tr key={index}>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900">{data?.name}</div>
            </td>

            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 capitalize">
                {data?.phone_number}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 capitalize">
                {data?.email_id}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 capitalize">
                {data?.shipping_address}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 capitalize">
                {data?.billing_address}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 capitalize">
                {data?.grand_total}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 capitalize cursor-pointer">
                <button
                  className="text-white text-sm bg-primary rounded-md py-1 px-2"
                  onClick={() => {
                    setEditData(data);
                    setOpenTracker(true);
                  }}
                >
                  Update
                </button>
              </div>
            </td>
          </tr>
        ))}
      </Table>
    </>
  );
}

export default OnlineAppointmentsTable