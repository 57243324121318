import React, { useEffect, useState } from 'react';
import { AddButton, ImageInput, NumberInput, SelectInput, ShowImage, TextAreaInput, TextInput } from '../Common/FormComponents';
import { GrFormClose } from 'react-icons/gr';
import { useForm } from '../../hooks';
import { validateTestModalForm } from '../../utils/testsUtils/validateTestForm';
import { addTest } from '../../utils/testsUtils/testsUtils';
import { getCategories } from '../../utils/categoriesUtils/categoriesUtils';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const TestModal = ({ setOpen, editData = null, setEditData, setTestsFn, user }) => {
  const testInitialState = {
    test_name: editData?.test_name || "",
    price: editData?.price?.toString() || "",
    discount: editData?.discount || 1,
    department: editData?.department || "Select Department",
    health_condition: editData?.health_condition || "",
    category: editData?.category?.category_name || "Select Category",
    service: editData?.service || "Select Service",
    test: editData?.test || "",
    method: editData?.method || "",
    patient_prepration: editData?.patient_prepration || "",
    clinical_utility: editData?.clinical_utility || "",
    test_required: editData?.test_required || "",
    sample: editData?.sample || "",
    age_group: editData?.age_group || "",
    gender: editData?.gender || "Select Gender",
  };
  const [test_pic, setTestPic] = useState(editData?.test_pic||null);
  const [testFormData, handleTestData,clearForm] = useForm(testInitialState);
  const [categories, setCategories] = useState([]);
  const [cats, setCats] = useState([]);
  const [catId, setCatId] = useState(0);
  useEffect(() => {
    getCategories()
      .then((res) => {
        setCategories([
          { category_name: "Select Category", _id: 0 },
          ...res.data.data,
        ]);
        setCats([
          "Select Category",
          ...res.data.data.map((item) => item.category_name),
        ]);
      })
      .catch((err) => {
        setCategories([{ category_name: "Select Category", _id: 0 }]);
      });
  }, []);
  useEffect(() => {
    const foundCategory = categories.find(
      (dep) => dep.category_name === testFormData.category
    );
    if (foundCategory) {
      setCatId(foundCategory._id);
    }
  }, [categories, testFormData.category]);
  const [loading,setLoading] = useState(false);
  const [err, setErr] = useState({ global: "" });
  const faqInitialState = { faq_question: "", faq_answer: "" };
  const [faqFormData, handleFaqFromData, clearFaqForm] =
    useForm(faqInitialState);
  const [faq, setFaqs] = useState(editData?.faq || []);
  const handleFaqs = (action, idx = 0) => {
    if (action === "add") {
      if (
        !faqFormData.faq_question.trim().length ||
        !faqFormData.faq_answer.trim().length
      ) {
        return setErr({ global: "Please Enter Question & Answer" });
      } else {
        setFaqs([
          ...faq,
          {
            faq_question: faqFormData.faq_question,
            faq_answer: faqFormData.faq_answer,
          },
        ]);
        clearFaqForm();
      }
    } else if (action === "remove") {
      setFaqs(faq.filter((_, index) => index !== idx));
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateTestModalForm(testFormData,test_pic,catId, setErr)) {
      setLoading(true);
      addTest(testFormData, test_pic, catId,faq, user?._id, editData?._id)
        .then((res) => {
          setTestsFn();
          setEditData(null);
          setTestPic(null);
          clearForm();
          setOpen(false);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  }
  return (
    <div className="absolute flex w-full h-screen top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div className="flex bg-white w-full max-w-screen-md overflow-y-auto h-full border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col">
        <div className="flex w-full h-fit items-center justify-between font-semibold">
          <span>{editData ? "Edit Test" : "Add New Test"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            name="Test Name"
            formName={"test_name"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
          <SelectInput
            data={testFormData}
            handleInput={handleTestData}
            items={cats}
            name={"Category"}
            formName={"category"}
            setError={setErr}
          />
          <SelectInput
            name="Service"
            formName={"service"}
            data={testFormData}
            handleInput={handleTestData}
            items={["Select Service", "lab/home", "lab", "home"]}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <SelectInput
            isHide={true}
            name="Department"
            formName={"department"}
            data={testFormData}
            handleInput={handleTestData}
            items={[
              "Select Department",
              "Clinical Biochemistry",
              "Clinical Pathology",
              "Serology",
              "Radiology",
            ]}
            setError={setErr}
          />
          <TextInput
            name="Price"
            formName={"price"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
          <NumberInput
            name="Discount"
            formName={"discount"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <SelectInput
            name="Gender"
            formName={"gender"}
            data={testFormData}
            handleInput={handleTestData}
            items={["Select Gender", "Male", "Female", "Both"]}
            setError={setErr}
          />
          <TextInput
            name="Sample"
            formName={"sample"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
          <TextInput
            name="Age Group"
            formName={"age_group"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextAreaInput
            name={"Description"}
            formName={"test"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
          <TextAreaInput
            name={"Method"}
            formName={"method"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextAreaInput
            name={"Test Required For"}
            formName={"test_required"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
          <TextAreaInput
            name={"Clinical Utility"}
            formName={"clinical_utility"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextAreaInput
            name={"Patient Prepration"}
            formName={"patient_prepration"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
          <TextAreaInput
            name={"Health Condition"}
            formName={"health_condition"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <ShowImage image={test_pic} setImage={setTestPic} />
          <ImageInput imageName={"Test Image"} setImage={setTestPic} />
        </div>
        {faq?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {faq?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item?.faq_question}</span>
                <button
                  onClick={() => {
                    handleFaqs("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="flex flex-col w-full gap-5">
          <TextInput
            data={faqFormData}
            handleInput={handleFaqFromData}
            name={"Question"}
            formName={"faq_question"}
            setError={setErr}
          />
          <div className="flex">
            <TextAreaInput
              data={faqFormData}
              handleInput={handleFaqFromData}
              name={"Answer"}
              formName={"faq_answer"}
              setError={setErr}
            />
            <AddButton handleAdd={handleFaqs} />
          </div>
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestModal