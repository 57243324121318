import React, { useMemo } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Table } from "../Common";
import { TbUserEdit } from "react-icons/tb";
import { deleteCategory } from "../../utils/categoriesUtils/categoriesUtils";

const CategoriesTable = ({ datas, setOpen, setEditData, setCategoriesFn }) => {
  const headings = useMemo(
    () => [
        "Category",
        "Edit",
        "Delete"
    ],
    []
  );
  const delCategory = (categoryId) => {
    deleteCategory(categoryId)
      .then((res) => setCategoriesFn())
      .catch((err) => console.log(err));
  };
  return (
    <Table headings={headings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                <img
                  className="h-10 w-10 rounded-full object-cover"
                  src={data?.category_icon}
                  alt="categroy_image"
                />
              </div>
              <div className="ml-4">
                <div className="text-sm font-medium text-gray-900">
                  {data?.category_name}
                </div>
              </div>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                setEditData(data);
                setOpen(true);
              }}
            >
              <TbUserEdit />
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                delCategory(data?._id);
              }}
            >
              <MdOutlineDeleteOutline color="red" />
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default CategoriesTable;
