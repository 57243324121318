export const validatePackageForm = (initialState,descripiton, tests, image, setErr) =>{
    if (!initialState.health_package_name.trim().length) {
        setErr({ global: "Invalid Package Name" });
        return false;
    }
    if (initialState.health_package_mrp < 0) {
        setErr({ global: "Invalid Package MRP" });
        return false;
    }
    if (initialState.health_package_discount < 0) {
        setErr({ global: "Invalid Package Discount" });
        return false;
    }
    if (initialState.gender === "Select Gender") {
        setErr({ global: "Invalid Gender" });
        return false;
    }
    if (!initialState.sample.trim().length) {
        setErr({ global: "Invalid Sample" });
        return false;
    }
    if (!initialState.age_group.trim().length) {
        setErr({ global: "Invalid Age Group" });
        return false;
    }
    if (!descripiton.replace(/<[^>]*>/g, "").trim().length) {
        setErr({ global: "Invalid Description" });
        return;
    }
    if (!tests.length) {
        setErr({ global: "Add At Least One Test" });
        return false;
    }
    return true;
}