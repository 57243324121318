import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";

const ShowFile = ({ file, setFile }) => {
  const getFileType = (fileName) => {
    const fileType = fileName.split(".").pop();
    return fileType.toLowerCase();
  };
const downloadFile = () => {
  const url = file;
  const a = document.createElement("a");
  a.href = url;
  a.download = file.name || "download";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
  const renderFileContent = () => {
    if (!file) {
      return (
        <img
          src="https://www.aputf.org/wp-content/uploads/2015/06/default-placeholder1-1024x1024-570x321.png"
          className="rounded-md border w-[282px] h-[159px] object-cover"
          alt="default_file"
        />
      );
    }

    const fileType = getFileType(file.name || ""); // Assuming file has a name property

    if (fileType === "jpg" || fileType === "png") {
      return (
        <img
          src={URL.createObjectURL(file)}
          className="rounded-md border w-[282px] h-[159px] object-cover"
          alt="user_image"
        />
      );
    } else if (fileType === "mp4") {
      return (
        <video
          className="w-full h-full object-cover"
          controls
          src={URL.createObjectURL(file)}
          alt="user_video"
        />
      );
    } else if (fileType === "pdf") {
      return (
        <iframe
          src={URL.createObjectURL(file)}
          className="w-full h-full rounded-md border"
          title="PDF Viewer"
        />
      );
    } else {
      return (
        <div className="rounded-md border w-[282px] h-[159px] bg-gray-200 flex items-center justify-center">
          <div className="text-center">
            <button className="text-sm text-gray-500 border border-primary p-2 rounded-full" onClick={downloadFile}>
              Download File
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="w-full relative max-h-[355px]">
      {renderFileContent()}
      {file && (
        <button className="flex absolute top-2 right-2">
          <AiOutlineCloseCircle
            fontSize={16}
            color="red"
            onClick={() => {
              setFile(null);
            }}
          />
        </button>
      )}
    </div>
  );
};

export default ShowFile;
